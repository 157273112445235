.avatar-container {
    display:flex;
    align-items:center;
    justify-content:flex-end;
    height:100%;
    z-index:200000;

}


.avatar {
position:relative;
left:10vw;
padding-bottom:10vh;
margin-right:-10vw;
max-width:400px;
width:30vw;
  z-index:200000;
}

 @media screen and (max-height: 500px) {
 .avatar {
 width:20vw;
 }
 }

 @media screen and (max-width: 1024px) {
 .avatar-container {
 width:100%;
 }

 .avatar {
 margin-right:-2vw;;
 margin-top:5vh;
 padding-bottom:2vh;
 width:60vw;
 max-width:300px;


left:0vw;

}

 }
 


 @media screen and (max-width: 480px) {

 .avatar {
    min-width:70vw;
 }
 }


