body {
  min-height: 100%;
  margin: 0;
  padding: 0;
  scrollbar-width: none !important;
}
html {
  height: 100%;
  width:100%
}

