

.logo {
    height:4vh;
    padding-top:20px;
    padding-left:20px;
    max-height:30px;
}

.logo-container {
    background-color:white!important;
    height:100%;
    position: fixed;
    z-index:100;
   
}

@media screen and (max-width: 1024px) {

.logo-container {
    
    height:5vh;
    min-height:80px;
    padding:0;
    background-color:white;
}

.logo {
	
	height:20vh;
	
}

@media screen and (max-width: 480px) {
.logo {
	height:10vh;
	 
}
}

}