.container {
    display:flex;
    width:100%;
    height:100%;
    justify-content:center;
}

.contact-container {
    display:flex;
    align-items: center;
    justify-content: center;
    width:50vw;
    height:100vh;
}



.contact-sup {
        text-align:left;
        overflow-y:scroll;
        height:70vh;
        font-family:'Roboto';
        font-size:1.5vw;
        width:40vw;
        letter-spacing: 1px;
      
}

.contact-sup::-webkit-scrollbar {
    display: none;
}

.contact {
    font-family:'Roboto';
    font-size:1.25vw;
    letter-spacing: 1px;
    text-align:right;
}

@media screen and (max-width: 1024px) {
    .contact {
        padding:0;
        width:70vw;
        font-size:4vw;
        height:70vh;
        text-align:left;
        padding-left:0vw;
    }

    .contact-container {
        width:80vw;
    }

    .contact-sup {
        padding-top:40vh;
        text-align:left;
        overflow-y:scroll;
        height:70vh;
        font-family:'Roboto';
        font-size:4vw;
        width:90vw;
        letter-spacing: 1px;
      
}
}