.container {
    display:flex;
    width:100%;
    height:100%;
    justify-content:center;
}

.portfolio-sup-container {
    display:flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width:50vw;
    height:100vh;
}

.portfolio-container {
    display:flex;
    align-items: center;
    justify-content: center;
    width:50vw;
    height:70vh;
}

.portfolio {
    overflow-y:scroll;
    height:60vh;
    font-family:'Roboto';
    font-size:1.25vw;
    width:40vw;
    letter-spacing: 1px;
    text-align:left;
    padding-right:5vw;
    padding-left:2vw;

}

.portfolio::-webkit-scrollbar {
    display: none;
}

.dribbble {
    padding-left:10vw;
    padding-top:3vh;
}

@media screen and (max-width: 1024px) {
    .portfolio {
        padding:0;
        width:70vw;
        font-size:4vw;
        height:70vh;
       
    }

    .portfolio-container {
        width:100vw;
        height:70vh;
        display:flex;
        align-items:center;
        padding-top:0vh;
    }

    .dribbble {
        width:30vw;
        padding:0;
    }
}

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
}

.nobreak {
    white-space: nowrap;
}

.pf-arrow {
    transform: translatey(0px);
    animation: pf-float 1.5s ease-in-out infinite;
    width:2vw;
    height:2vw;
    opacity:0.4;
}

@media screen and (max-width: 1024px) {
    .portfolio {
  
        padding:0;
        width:80vw;
        font-size:2vw;
        height:70vh;
        top:2vh;
           -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;
    }



    .pf-arrow {
        width:4vw;
        height:4vw;
        top:8vh;
    }
}

@keyframes pf-float {
	0% {
		box-shadow: 0 px 0px 0px rgba(0,0,0,0.6);
		transform: translatey(0px);
	}
	50% {
		box-shadow: 0 0px 0px 0px rgba(0,0,0,0.2);
		transform: translatey(-20px);
	}
	100% {
		box-shadow: 0 0px 0px 0px rgba(0,0,0,0.6);
		transform: translatey(0px);
	}
}

@media screen and (max-width: 480px) {
    .portfolio {
      

        font-size:4vw;
       
    }
}

.hidden {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 0.2s, opacity 0.2s linear;
  }