.container {
    display:flex;
    width:100%;
    height:100%;
    justify-content:center;
    background-color:white;

}



.img-container {
    display:flex;
    align-items: center;
    width:30vw;
    height:100vh;
    background-color:white;
}

.img-container:focus {
    outline: none;
}

.video-react-video:focus {
    outline: none;
}
 :focus { outline: none; }

.artwork {
    height:100vh;
    background-color:white;
}

.test {
    height:100vh!important;
    background-color:white!important;
}

.note {
    display:none;
}

@media screen and (max-width: 1024px) {
    
    .img-container {
        display:none
    } 


}

@media screen and (min-width: 1025px) {
    .mobile-msg {
        display:none
    }
}

.mobile-msg {

    position: relative;
    font-family:'Roboto';
    font-size:1.5vh;
    letter-spacing: 0px;
    text-align:center;
}

.ghost-button {
    display: inline-block;
    width: 160px;
    height:40px;
    padding: 8px;
    color: #ffffff;
    text-transform:uppercase;
    border: 2px solid #252525;
    text-align: center;
    font-size:15px;
    outline: none;
    text-decoration: none;
    background-color:#252525;
    margin-top:10px;

  }
  
  .ghost-button:hover,
  .ghost-button:active {
    background-color: #ffffff;
    color: #252525;
  }

  @media screen and (max-width: 1024px) {
    .container {
    display:flex;
    flex-direction:column-reverse;
    align-items:center;
    justify-content:center;
    }

    .mobile-msg {
    width:60vw;
    display:flex;
justify-content:center;
flex-direction:column;
align-items:center;
    }

    .note {
        display:block;
        font-size:1.1em;
        margin-top : 15px;
        width:40vw;
       
    }

    .first-line {
    font-size:1.4em
    }
}



