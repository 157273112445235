.header-sup {
 
    min-height:100vh;
    height:100vh;
    

}

.nav {
    display:flex;
    align-items:center;
    justify-content:center;
    min-height:100%;
    font-family: Roboto, sans-serif;
    font-size:2rem;
    padding-bottom:20vh;
    padding-left:8vw;

}

#nav-sub {
    display:flex;
    flex-direction:column;
    justify-content: flex-end;
}


.menu-item {
    display:flex;
    justify-content: flex-start;
}
.menu-text {
    color:#252525;
    font-family: Roboto, sans-serif;
    font-weight: 800;
    font-style: normal;
    text-transform:uppercase;
    font-size: 1.2vw;
    letter-spacing:8px;
    text-align:left;
    line-height: 150%;
    text-decoration: none;

    &:hover {
        color:#f1f1f1;
        text-shadow:-1px 0 #252525, 0 1px #252525, 1px 0 #252525, 0 -1px #252525;
    }
}



.socials-footer {
    display:flex;
    height:100%;
    flex-direction:row;;
    align-items:flex-end;
    justify-content:center;
    margin-top:10vh;
    padding-top:5vh;
  
}
.footer-text {
    font-family: Roboto, sans-serif;
    font-weight: 800;
    font-style: normal;
    text-transform:uppercase;
    text-align:center;
    font-size:3vh;
    margin-block-end:1vh;
    letter-spacing:2px;
}

.right-stuff {
    height:100%;
    min-height:100%;
    width:30vw;
    display:flex;
    flex-direction:column;
    justify-content:center;
    z-index:1;
    background-color:#f1f1f1;

}

.socials-icon {
    height:3vh;
    padding:1vh
}

.socials-icons {
    display:flex;
    justify-content:center;
}

@media screen and (min-width: 1024px) {
    .hamburger-menu {
      display:none;
    }

    .menu-bar {
      display:none;
    }
}

@media screen and (max-width: 1025px) {
    .right-stuff {
    display:none;
    width:60vw;
    }


    .menu-text {
        font-size:4vw;
        color: white;
        letter-spacing:4px;
    }

    .come-on-in {
        position: fixed;
        left:100vw;;
        top: 10vh;
        width: 100vw;
        height: calc(100vh - 70px);
        background-color: #252525;
        transform: translateX(-100%);
        transition: transform 0.5s ease-in-out;
    }
    
    .come-on-out {
        position: fixed;
        left:100vw;;
        top: 10vh;
        width: 100vw;
        height: calc(100vh - 70px);
        background-color: #252525;
        transform: translateX(100%);
        transition: transform 0.5s ease-in-out;
    }

    .menu-bar {
  position:fixed;
  width: 30px;
  height:30px;
  z-index:100;
  left:90vw;
  top:25px;
  

}
}

.hamburger-menu {
    position:fixed;
    font-family: Roboto, sans-serif;
    font-weight: 800;
    font-style: normal;
    text-transform:uppercase;
    text-align:center;
    font-size:3vh;
    z-index:100;
    left:100vw;
    display:flex;
    flex-direction: column;
    align-content:center;
    justify-content:center;


}

.menu-item-mobile {
    text-align:center;
    padding:3vw;
}


@media screen and (max-width: 480px) {
.menu-bar {
  position:fixed;
  width: 30px;
  height:30px;
  z-index:100;
  left:80vw;
  top:25px;
  
}
}



