.container {
    display:flex;
    width:100%;
    height:100%;
    justify-content:center;
}

.cv-sup-container {
    display:flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width:50vw;
    height:100vh;
}

.cv-container {
    display:flex;
    align-items: center;
    justify-content: center;
    width:50vw;
    height:70vh;
}

.cv {
    overflow-y:scroll;
    height:60vh;
    font-family:'Roboto';
    font-size:1.25vw;
    width:40vw;
    letter-spacing: 1px;
    text-align:left;
    padding-right:5vw;
    padding-left:2vw;

}

.cv::-webkit-scrollbar {
    display: none;
}

.date {
    font-size:1vw;
    color:#777777;
     margin-bottom: 0;
    padding-bottom: 0;
    
}

.title {
    
     margin: 0;
    padding: 0;
    
}

.dribbble {
    padding-left:10vw;
    padding-top:3vh;
}

@media screen and (max-width: 1024px) {
    .cv-sup-container {
    display:flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width:100vw;
    height:100vh;

}


    

    .date {
        font-size:3vw;
    }
        

    .cv-container {
        width:100vw;
        height:70vh;
        display:flex;
        align-items:center;
        padding-top:0vh;

    }

    .dribbble {
        width:30vw;
        padding:0;
    }
}

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
}

.nobreak {
    white-space: nowrap;
}

.cv-arrow {
    transform: translatey(0px);
    position:relative;
    right:2vw;
    top:2vw;
    animation: cv-float 1.5s ease-in-out infinite;
    width:3vw;
    height:3vw;
}

@media screen and (max-width: 1024px) {
    .cv {
    
        padding:0;
        width:80vw;
        font-size:2vw;
        height:70vh;
        top:2vh;
         -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;
    }


    .cv-arrow {
        width:4vw;
        height:4vw;
        top:8vh;
    }
}

@media screen and (max-width: 480px) {
    .cv {
      

        font-size:4vw;
       
    }
}


@keyframes cv-float {
	0% {
		box-shadow: 0 px 0px 0px rgba(0,0,0,0.6);
		transform: translatey(0px);
	}
	50% {
		box-shadow: 0 0px 0px 0px rgba(0,0,0,0.2);
		transform: translatey(-20px);
	}
	100% {
		box-shadow: 0 0px 0px 0px rgba(0,0,0,0.6);
		transform: translatey(0px);
	}
}


.hidden {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 0.2s, opacity 0.2s linear;
  }


  .arrow-container {
  display:flex;
  justify-content:center;
  flex-direction:column;
  align-items:center;
  margin-top:30px;
  }

.scroll-for-more {
    font-family:Roboto;
    font-size:0.8em;
    color:grey;
    
}
