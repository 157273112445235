.App {
  display:flex;
  justify-content: flex-start;
  align-items:flex-start;
 
  min-height: 100%;
  margin: 0;
  padding: 0;
  background-color:#ffffff

}

.logo {
  position:fixed;
  z-index:1000;
  height:10vw;
  background-color:white;

}

@media screen and (max-width: 1024px) {
.white-hdr {
  display:block;
 position:fixed;
 height:80px;
 width:100vw;
 background-color: white;
 z-index: 2;
}

}
.headr {
  min-height:100%;
}

@media screen and (max-height: 768px) {
.white-hdr {
  display:block;
 position:fixed;
 height:65px;
 width:100vw;
 background-color: white;
 z-index: 2;
}

}